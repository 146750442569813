import logo from "./logo.png";

function App() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={logo}
        alt="Diteck"
        style={{
          width: "100%",
          maxWidth: 250,
        }}
      />
    </div>
  );
}

export default App;
